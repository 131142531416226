import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import { DarkGrey, PrimaryColor } from "../../shared/styles/colors";
import DeleteModal from "../modal/DeleteModal";
import CloseIcon from "@mui/icons-material/Close";
import ServiceModel from "../../model/service";
import Status from "../../model/enum/StatusClass";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import EditIcon from "../../assets/admin/edit-icon.svg";
import useStyles from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const ServiceModal = ({
  service,
  handleUpdate,
  handleCreate,
  handleDelete,
}: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const edit = service != null;
  const [name, setName] = useState(edit ? service.name : "");
  const [availability, setAvailability] = useState(
    edit ? service.available : true
  );

  const handleName = (e: any) => {
    e.preventDefault();
    setName(e.target.value);
  };

  const handleAvailability = () => {
    setAvailability(!availability);
  };

  const submit = async () => {
    if (invalidInput()) return;

    let payload: ServiceModel = {
      id: edit ? service.id : 0,
      name: name,
      available: availability,
    };

    if (edit) await handleUpdate(payload);
    else await handleCreate(payload);

    handleClose();
  };

  const invalidInput = (): boolean => {
    return name === "";
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : (
        <Btn
          className={classes.headerButton}
          variant="contained"
          onClick={handleOpen}
        >
          + Add Service
        </Btn>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            {edit ? (
              <Typography className={classes.title}>
                <DeleteModal item={service} handleDelete={handleDelete} />
              </Typography>
            ) : (
              <Typography className={classes.title}>Add new service</Typography>
            )}
            <Button onClick={handleClose} sx={{ color: "black" }}>
              <CloseIcon />
            </Button>
          </div>
          {edit && <hr />}
          <form className={classes.form}>
            <input
              className={classes.input}
              value={name}
              onChange={handleName}
              placeholder="Name"
            />
            <div className={classes.availabilityDiv}>
              <Typography>{Status.AVAILABLE}:</Typography>
              {availability ? (
                <Button
                  sx={{ color: PrimaryColor }}
                  onClick={handleAvailability}
                >
                  <ToggleOnIcon />
                </Button>
              ) : (
                <Button sx={{ color: DarkGrey }} onClick={handleAvailability}>
                  <ToggleOffIcon />
                </Button>
              )}
            </div>
          </form>
          <div className={classes.buttonDiv}>
            <Btn
              className={classes.button}
              onClick={submit}
              disabled={invalidInput()}
            >
              Save
            </Btn>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ServiceModal;
