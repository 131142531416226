import React, { useEffect, useState } from "react";
import { MobileStepper } from "@mui/material";
import { Card, CardContent, Button, CardActions } from "@material-ui/core";
import { Step1, Step2, Step3, BreadCrumb } from "./steps/index";
import { Helmet } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import ClientModel from "../../model/client";
import ClientServices from "../../model/enum/ClientServices";
import ToastMessages from "../../model/enum/ToastMessages";
import ThanksPage from "../../shared/components/greetings/ThanksPage";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import RequestModel from "../../model/request";
import EmailService from "../../service/EmailService";
import AlertService from "../../service/AlertService";
import SmsService from "../../service/SmsService";
import ApiService from "../../service/ApiService";
import US_States from "../../model/enum/US_States";
import useStyles from "./styles";
import Service from "../../model/service";
import ServiceType from "../../model/serviceType";
import Pathnames from "../../model/enum/Pathnames";
import { useNavigate } from "react-router-dom";

const Request = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [zipCode, setZipCode] = useState("");
  const [serviceId, setServiceId] = useState(0);
  const [serviceTypeId, setServiceTypeId] = useState(0);
  const [description, setDescription] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [toggleSpinner, setToggleSpinner] = useState(false);

  const US_State = new US_States();
  const [services, setServices] = useState<Service[]>([]);
  const [serviceTypes, setServiceTypes] = useState<ServiceType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<Service[]>("frontend/services").then((data) => {
        data.map((x: Service) => {
          if (x.available) setServices((data) => [...data, x]);
        });
      });
      await ApiService.get<ServiceType[]>("frontend/serviceTypes").then(
        (data) => {
          setServiceTypes(data);
        }
      );
    };

    if (services.length <= 0) fetchData();
  }, [services, serviceTypes]);

  const handleNextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePreviousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleZipCode = (e: any) => {
    e.preventDefault();
    let zip = e.target.value;
    if (!isNaN(zip)) {
      if (US_State.isZipValid(zip)) {
        setCity(US_State.getStateCity(zip));
        setState(US_State.getStateName(zip));
      } else {
        setCity("");
        setState("");
      }
      setZipCode(zip);
    }
  };

  const handleServiceType = (e: any) => {
    setServiceTypeId(e.target.value);
  };

  const handleService = (e: any) => {
    setServiceId(e.target.value);
  };

  const handleDescription = (e: any) => {
    setDescription(e.target.value);
  };

  const handleAddress = (e: any) => {
    setAddress(e.target.value);
  };

  const handleFirstName = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const handleEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handlePhone = (e: any) => {
    setPhone(e);
  };

  const disableNextStep = () => {
    switch (activeStep) {
      case 0:
        return !zipCode;
      case 1:
        return serviceTypeId === 0 || serviceId === 0 || description === "";
      case 3:
        return (
          address === "" ||
          firstName === "" ||
          lastName === "" ||
          email === "" ||
          phone === ""
        );
      default:
        return false;
    }
  };

  const invalidInput = () => {
    return (
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      email.indexOf("@") === -1 ||
      email.indexOf(".") === -1 ||
      phone === "" ||
      address === "" ||
      state === "" ||
      !zipCode ||
      serviceTypeId === 0 ||
      serviceId === 0 ||
      description === "" ||
      toggleSpinner
    );
  };

  const handleSubmit = async () => {
    if (!invalidInput()) {
      setToggleSpinner(true);

      const client: ClientModel = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        zip: zipCode,
        city: city,
        state: state,
        street: address,
        service: ClientServices.APPOINTMENT,
      };

      var clientId = 0;

      await ApiService.post<any>("frontend/client", client)
        .then((cl) => {
          if (cl.error && cl.existingClient) clientId = cl.existingClient.id;
          else clientId = cl.id;
        })
        .catch(() => {
          AlertService.error(ToastMessages.REQUEST_FAILED);
          setToggleSpinner(false);
          return;
        });

      const payload: RequestModel = {
        serviceId: serviceId,
        serviceTypeId: serviceTypeId,
        description: description,
        clientId: clientId,
      };

      await ApiService.post<any>("frontend/request", payload)
        .then(async () => {
          AlertService.succes(ToastMessages.REQUEST_SENT);
          await SmsService.send(phone, "Service Request");
          await EmailService.sendEmailToUser(
            firstName,
            email,
            "Service Request"
          )
            .then(() => {
              setZipCode("");
              setCity("");
              setState("");
              setServiceTypeId(0);
              setServiceId(0);
              setDescription("");
              setAddress("");
              setFirstName("");
              setLastName("");
              setEmail("");
              setPhone("");
              setActiveStep(0);
            })
            .finally(() => {
              navigate(Pathnames.THANKYOU, {
                state: { service: "request" },
              });
            });
        })
        .catch(() => {
          AlertService.error(ToastMessages.REQUEST_FAILED);
        });
      setToggleSpinner(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Request Service - Setup Remodeling</title>
        <meta
          name="description"
          content="Get a free quote today with our experts and find the best solution for your dream house. Setup Remodeling"
        ></meta>
        <link rel="canonical" href="/quote" />
      </Helmet>
      <main className={classes.request}>
        <Card className={classes.card} raised={true}>
          <BreadCrumb step={activeStep} />
          <hr style={{ width: "200%", marginTop: "5%" }} />
          <CardContent>
            {activeStep === 0 ? (
              <Step1
                handleZipCode={handleZipCode}
                zipCode={zipCode}
                city={city}
                state={state}
              />
            ) : activeStep === 1 ? (
              <Step2
                handleServiceType={handleServiceType}
                serviceTypeId={serviceTypeId}
                handleService={handleService}
                serviceId={serviceId}
                handleDescription={handleDescription}
                description={description}
                services={services}
                serviceTypes={serviceTypes}
              />
            ) : activeStep === 2 ? (
              <Step3
                handleAddress={handleAddress}
                address={address}
                handleFirstName={handleFirstName}
                firstName={firstName}
                handleLastName={handleLastName}
                lastName={lastName}
                handleEmail={handleEmail}
                email={email}
                handlePhone={handlePhone}
                phone={phone}
              />
            ) : null}
          </CardContent>
          <CardActions className="d-flex row justify-content-center">
            <MobileStepper
              variant="dots"
              steps={3}
              position="static"
              activeStep={activeStep}
              sx={{
                minWidth: 900,
                flexGrow: 1,
                "@media (max-width: 640px)": { minWidth: 300 },
              }}
              nextButton={
                <Button
                  size="medium"
                  onClick={handleNextStep}
                  disabled={activeStep === 2 || disableNextStep()}
                >
                  <ArrowCircleRightIcon
                    className={
                      activeStep < 2 && !disableNextStep()
                        ? classes.breadcrumbCircular
                        : classes.breadcrumbCircularDisabled
                    }
                  />
                </Button>
              }
              backButton={
                <Button
                  size="medium"
                  onClick={handlePreviousStep}
                  disabled={activeStep === 0}
                >
                  <ArrowCircleLeftIcon
                    className={
                      activeStep > 0
                        ? classes.breadcrumbCircular
                        : classes.breadcrumbCircularDisabled
                    }
                  />
                </Button>
              }
            />
            <Button
              className={classes.button}
              disabled={invalidInput()}
              onClick={handleSubmit}
              hidden={activeStep !== 2}
            >
              Submit
            </Button>
          </CardActions>
        </Card>
        <ToastContainer />
      </main>
    </>
  );
};

export default Request;
