import TransformDate from "../utils/TransformDate";

const token = process.env.REACT_APP_SMS_TOKEN;
const sender = "+18005304099";
const api = "https://api.800.com/v1/messages";

export default class SmsService {
  static send = async (
    phone: string,
    serviceType: string,
    startingTime?: string,
    endingTime?: string,
    date?: Date
  ) => {
    if (phone == null) return;

    const data = {
      sender: sender,
      recipient: "+" + phone,
      message: mapResponseMessage(serviceType, startingTime, endingTime, date),
    };

    try {
      const response = await fetch(api, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error posting data:", error);
      throw error;
    }
  };
}

const mapResponseMessage = (
  serviceType: string,
  startingTime?: string,
  endingTime?: string,
  date?: Date
): string => {
  let message: string = "";
  startingTime = TransformDate.convertTo12HourFormat(startingTime);
  endingTime = TransformDate.convertTo12HourFormat(endingTime);

  switch (serviceType) {
    case "Appointment Schedule":
      if (date)
        message = `We're working on your request to schedule an appointment.<h3> On 
        ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} 
        from ${startingTime} to ${endingTime}.<h2> We will soon reply to you. </h2> Setup Remodeling`;
      break;
    case "Appointment Confirmed":
      if (date)
        message = `Your appointment is confirmed for ${date.toLocaleString(
          "default",
          { month: "long" }
        )} ${date.getDate()}, ${date.getFullYear()}, from ${startingTime} to ${endingTime}. 
        We will be at your location during this time. Thank you. Setup Remodeling`;
      break;
    case "Appointment Denied":
      message =
        "Your appointment has been denied. There are no more openings for that day. What would be another suitable date for you?. Thank You! Setup Remodeling";
      break;
    case "Contact Message":
      message =
        "We received your contact message. We will soon reply to you. Thank You! Setup Remodeling";
      break;
    case "Contact Accepted":
      message =
        "Your message has been accepted. We will soon reply to you. Thank You! Setup Remodeling";
      break;
    case "Contact Denied":
      message = "Your message has been denied. Thank You! Setup Remodeling";
      break;
    case "Service Request":
      message =
        "We're working on your service request. We will soon reply to you. Thank You! Setup Remodeling";
      break;
    case "Request Accepted":
      message =
        "Your request has been accepted. We're currently preparing your estimate and will be in touch shortly to discuss some details with you. Setup Remodeling";
      break;
    case "Request Denied":
      message =
        "Your request has been denied. We apologize for any inconvenience. Thank You! Setup Remodeling";
      break;
    case "Service Completed":
      message =
        "Your valuable feedback means the world to us! Please give us a review! \n\n On https://setupremodeling.com/reviews . Thank You! Setup Remodeling"; //url https://setupremodeling.com/reviews/writeReview
      break;
    case "Estimate Approved":
      if (date)
        message = `Thank you for approving the estimate! We're excited to start your project on ${
          date.getMonth() + 1
        }/${date.getDate()}/${date.getFullYear()} at ${startingTime}.
        If you have any questions before then, feel free to reach out. Looking forward to working with you! Setup Remodeling`;
      break;
    default:
      break;
  }

  return message;
};
