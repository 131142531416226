import React, { useEffect, useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import AlertService from "../../service/AlertService";
import { ToastContainer } from "react-toastify";
import FirebaseService from "../../service/FirebaseService";
import ImageCompressor from "../../utils/ImageCompressor";
import { Spinner } from "../../components";
import useStyles from "../styles/styles";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import CloseIcon from "@mui/icons-material/Close";
import ToastMessages from "../../model/enum/ToastMessages";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import ServiceModel from "../../model/service";
import { Button as Btn, Typography } from "@material-ui/core";
import UploadIcon from "../../assets/admin/upload-icon.svg";
import { DarkGrey, PrimaryColor } from "../../shared/styles/colors";
import ApiService from "../../service/ApiService";
import GalleryModel from "../../model/gallery";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "85%",
  },
};

const PostToGallery = ({ handleCreate }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [fileName, setFileName] = useState("");
  const [serviceId, setServiceId] = useState();
  const [serviceType, setServiceType] = useState<ServiceModel>();
  const [services, setServices] = useState<ServiceModel[]>([]);
  const [description, setDescription] = useState("");
  const [uploading, setUploading] = useState(false);
  const [toggleCompression, setToggleCompression] = useState(false);

  //Get all services from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<ServiceModel[]>(DatabaseEntities.SERVICES).then(
        (data) => {
          setServices(data);
        }
      );
    };

    if (services.length <= 0) fetchData();
  }, [services]);

  const handleTypeChange = (e: any) => {
    let value = e.target.value;

    setServiceId(value);
    services.forEach((srvc: ServiceModel) => {
      if (srvc.id == value) setServiceType(srvc);
    });
  };

  const handleFileChange = async (e: any) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value);
  };

  const handleCompression = () => {
    setToggleCompression((toggleCompression) => !toggleCompression);
  };

  const handleUpload = async () => {
    if (file === null || file === undefined) {
      AlertService.warning(ToastMessages.SELECT_FILE);
      return;
    }

    if (toggleCompression) {
      const compressedFile = await ImageCompressor.compress(file);
      setFile(compressedFile);
    }

    setUploading(true);

    if (serviceType && serviceId)
      await FirebaseService.uploadFile(file, fileName, serviceType.name)
        .then(async (url: string) => {
          const payload: GalleryModel = {
            description: description,
            imageUrl: url,
            serviceId: serviceId,
          };

          await handleCreate(payload).then(() => {
            setUploading(false);
            setDescription("");
            setServiceType(undefined);
            setFileName("");
            setFile(undefined);
            handleClose();
          });
        })
        .catch(() => {
          setUploading(false);
        });
  };

  const validInput = () => {
    return (
      fileName === "" ||
      serviceId === serviceType?.id ||
      file === null ||
      uploading
    );
  };

  return (
    <>
      {uploading && <Spinner />}
      <div>
        <Btn className={classes.headerButton} onClick={handleOpen}>
          + Add Item
        </Btn>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ overflowY: "auto" }}
        >
          <Box sx={style}>
            <div className={classes.headerDiv}>
              <Typography className={classes.title}>
                Add item to gallery
              </Typography>
              <Button onClick={handleClose} sx={{ color: "black" }}>
                <CloseIcon />
              </Button>
            </div>
            <div className={classes.inputGroup}>
              <form className={classes.inputDiv}>
                <select
                  onChange={handleTypeChange}
                  className={classes.input}
                  defaultValue={""}
                >
                  <option value="">Select a service</option>
                  {services.map((srvc: ServiceModel) => {
                    return (
                      <option key={srvc.id} value={srvc.id}>
                        {srvc.name}
                      </option>
                    );
                  })}
                </select>
                <textarea
                  className={classes.textarea}
                  placeholder="Add a description"
                  onChange={handleDescriptionChange}
                />
              </form>
              <div className={classes.inputDiv}>
                <div className={classes.uploadInput}>
                  <input
                    type="file"
                    id="add-single-img"
                    accept="image/*"
                    className="d-none"
                    onChange={handleFileChange}
                  />
                  <label
                    id="image-label"
                    htmlFor="add-single-img"
                    className={classes.label}
                  >
                    <img
                      src={UploadIcon}
                      className={classes.image}
                      alt="upload icon"
                    />
                    <label>Drop files to upload or browse</label>
                    <Typography>{fileName}</Typography>
                  </label>
                </div>
                <div className={classes.compressionDiv}>
                  <Typography>Compress the image: </Typography>
                  {toggleCompression ? (
                    <Button
                      sx={{ color: PrimaryColor }}
                      onClick={handleCompression}
                    >
                      <ToggleOnIcon />
                    </Button>
                  ) : (
                    <Button
                      sx={{ color: DarkGrey }}
                      onClick={handleCompression}
                    >
                      <ToggleOffIcon />
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div className={classes.buttonDiv}>
              <Btn
                className={classes.button}
                onClick={handleUpload}
                disabled={validInput()}
              >
                Upload
              </Btn>
            </div>
            <ToastContainer />
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default PostToGallery;
