import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Typography } from "@material-ui/core";
import FaqModel from "../../model/faq";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import Status from "../../model/enum/StatusClass";
import FaqModal from "./FaqModal";
import useStyles from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35rem",
  bgcolor: "background.paper",
  order: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

interface Props {
  faq: FaqModel;
  handleUpdate: any;
  handleDelete: any;
}

const FaqDetailModal = ({ faq, handleUpdate, handleDelete }: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <MoreVertIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            <Typography className={classes.title}>
              <div
                style={Status.mapStatusDivColor(
                  faq.available ? Status.ACTIVE : Status.INACTIVE
                )}
              >
                <div
                  style={Status.mapStatusRoundedColor(
                    faq.available ? Status.ACTIVE : Status.INACTIVE,
                    false
                  )}
                ></div>
              </div>
            </Typography>
            <div className={classes.headerSubDiv}>
              <FaqModal
                faq={faq}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
              />
              <Button onClick={handleClose} sx={{ color: "black" }}>
                <CloseIcon />
              </Button>
            </div>
          </div>
          <form className={classes.form}>
            <Typography className={classes.label}>Question</Typography>
            <input
              className={classes.inputDetail}
              value={faq.question}
              disabled
            />
            <Typography className={classes.label}>Answer</Typography>
            <textarea
              className={classes.textareaDetail}
              value={faq.answer}
              disabled
            />
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default FaqDetailModal;
