export default class Status {
  // All
  static readonly ACCEPTED = "Accepted";
  static readonly PENDING = "Pending";
  static readonly COMPLETED = "Completed";
  static readonly CANCELED = "Canceled";
  static readonly ON_HOLD = "On hold";
  static readonly ONGOING = "Ongoing";
  static readonly ESTIMATE_APPROVED = "Estimate approved";
  static readonly ON_DECISION = "On decision";

  // Other
  static readonly ACTIVE = "Active";
  static readonly INACTIVE = "Inactive";

  // Estimates
  static readonly PAID = "Paid";
  static readonly UNPAID = "Unpaid";
  static readonly EXPIRED = "Expired";
  static readonly DECLINED = "Declined";
  static readonly PARTIAL_PAID = "Partial paid";

  // Services
  static readonly AVAILABLE = "Available";
  static readonly UNAVAILABLE = "Unavailable";

  static readonly ALL = [
    Status.ACCEPTED,
    Status.PENDING,
    Status.ONGOING,
    Status.ON_HOLD,
    Status.CANCELED,
    Status.COMPLETED,
  ];

  static readonly FAQ_STATUS = [Status.ACTIVE, Status.INACTIVE];

  static readonly ESTIMATES_STATUS = [
    Status.PAID,
    Status.UNPAID,
    Status.EXPIRED,
    Status.DECLINED,
  ];

  static mapStatusColor = (status: string | undefined) => {
    if (!status) return;

    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "black",
      background: "white",
      borderRadius: "8px",
      padding: "8px",
      width: "100%",
    };

    switch (status) {
      case Status.ACCEPTED:
        style.color = "green";
        style.background = "rgba(221, 252, 210, 1)";
        break;
      case Status.ACTIVE:
        style.color = "green";
        style.background = "rgba(221, 252, 210, 1)";
        break;
      case Status.CANCELED:
        style.color = "red";
        style.background = "rgba(252, 210, 210, 1)";
        break;
      case Status.INACTIVE:
        style.color = "red";
        style.background = "rgba(252, 210, 210, 1)";
        break;
      case Status.PENDING:
        style.color = "orange";
        style.background = "rgba(245, 245, 204, 1)";
        break;
      case Status.COMPLETED:
        style.color = "rgba(28, 112, 239, 1)";
        style.background = "rgba(210, 226, 252, 1)";
        break;
      case Status.ON_HOLD:
        style.color = "orange";
        style.background = "rgba(245, 245, 204, 1)";
        break;
      case Status.ONGOING:
        style.color = "#182F58";
        style.background = "#A3ACBC";
        break;
      case Status.ESTIMATE_APPROVED:
        style.color = "#d4edda";
        style.background = "#28a745";
        break;
      case Status.ON_DECISION:
        style.color = "#fff3cd";
        style.background = "#f8c471";
        break;
      case Status.PAID:
        style.color = "#28a745";
        style.background = "#d4edda";
        break;
      case Status.UNPAID:
        style.color = "#dc3545";
        style.background = "#f8d7da";
        break;
      case Status.EXPIRED:
        style.color = "#ffc107";
        style.background = "#fff3cd";
        break;
      case Status.DECLINED:
        style.color = "#dc3545";
        style.background = "#f8d7da";
        break;
      case Status.PARTIAL_PAID:
        style.color = "#ffc107";
        style.background = "#fff3cd";
        break;
      default:
        break;
    }

    return style;
  };

  static mapStatusDivColor = (status: string | undefined) => {
    if (!status) return;

    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "30px",
      height: "30px",
      color: "black",
      background: "white",
      borderRadius: "22px",
    };

    switch (status) {
      case Status.ACCEPTED:
        style.color = "green";
        style.background = "rgba(221, 252, 210, 1)";
        break;
      case Status.ACTIVE:
        style.color = "green";
        style.background = "rgba(221, 252, 210, 1)";
        break;
      case Status.CANCELED:
        style.color = "red";
        style.background = "rgba(252, 210, 210, 1)";
        break;
      case Status.INACTIVE:
        style.color = "red";
        style.background = "rgba(252, 210, 210, 1)";
        break;
      case Status.PENDING:
        style.color = "orange";
        style.background = "rgba(245, 245, 204, 1)";
        break;
      case Status.COMPLETED:
        style.color = "rgba(28, 112, 239, 1)";
        style.background = "rgba(210, 226, 252, 1)";
        break;
      case Status.ON_HOLD:
        style.color = "orange";
        style.background = "rgba(245, 245, 204, 1)";
        break;
      case Status.ONGOING:
        style.color = "#182F58";
        style.background = "#A3ACBC";
        break;
      case Status.ESTIMATE_APPROVED:
        style.color = "#d4edda";
        style.background = "#28a745";
        break;
      case Status.ON_DECISION:
        style.color = "#fff3cd";
        style.background = "#f8c471";
        break;
      case Status.PAID:
        style.color = "#28a745";
        style.background = "#d4edda";
        break;
      case Status.UNPAID:
        style.color = "#dc3545";
        style.background = "#f8d7da";
        break;
      case Status.EXPIRED:
        style.color = "#ffc107";
        style.background = "#fff3cd";
        break;
      case Status.DECLINED:
        style.color = "#dc3545";
        style.background = "#f8d7da";
        break;
      case Status.PARTIAL_PAID:
        style.color = "#ffc107";
        style.background = "#fff3cd";
        break;
      default:
        break;
    }

    return style;
  };

  static mapStatusRoundedColor = (
    status: string | undefined,
    giveMargin: boolean = true
  ) => {
    if (!status) return;

    let style = {
      width: "5px",
      height: "5px",
      background: "white",
      borderRadius: "22px",
      marginRight: "0px",
    };

    if (giveMargin) style.marginRight = "5px";

    switch (status) {
      case Status.ACCEPTED:
        style.background = "green";
        break;
      case Status.ACTIVE:
        style.background = "green";
        break;
      case Status.CANCELED:
        style.background = "red";
        break;
      case Status.INACTIVE:
        style.background = "red";
        break;
      case Status.PENDING:
        style.background = "orange";
        break;
      case Status.COMPLETED:
        style.background = "rgba(28, 112, 239, 1)";
        break;
      case Status.ON_HOLD:
        style.background = "orange";
        break;
      case Status.ONGOING:
        style.background = "#182F58";
        break;
      case Status.ESTIMATE_APPROVED:
        style.background = "#d4edda";
        break;
      case Status.ON_DECISION:
        style.background = "#fff3cd";
        //style.background = "#f8c471";
        break;
      case Status.PAID:
        style.background = "#28a745";
        break;
      case Status.UNPAID:
        style.background = "#dc3545";
        break;
      case Status.EXPIRED:
        style.background = "#ffc107";
        break;
      case Status.DECLINED:
        style.background = "#dc3545";
        break;
      case Status.PARTIAL_PAID:
        style.background = "#ffc107";
        break;
      default:
        break;
    }

    return style;
  };

  static mapServiceStatusColor = (status: boolean) => {
    let style = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "black",
      background: "white",
      borderRadius: "8px",
      padding: "8px",
      maxWidth: "130px",
    };

    if (status) {
      style.color = "green";
      style.background = "rgba(221, 252, 210, 1)";
    } else if (!status) {
      style.color = "red";
      style.background = "rgba(252, 210, 210, 1)";
    }

    return style;
  };
}
