import { makeStyles } from "@material-ui/core/styles";
import { LightPrimaryColor, PrimaryColor, White, BackgroundBlue } from "../../styles/colors";

export default makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    background: BackgroundBlue,
  },
  card: {
    width: "1000px !important",
    marginTop: "150px",
    marginBottom: "50px",
    borderRadius: "25px !important",
    padding: "5rem",
    "@media (max-width: 640px)": {
      width: "90% !important",
      padding: 0,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    fontSize: "1.3rem",
    fontWeight: "bold",
    color: PrimaryColor,
  },
  image: {
    width: "300px",
    marginTop: "50px",
    "@media (max-width: 640px)": {
      width: "80%",
    },
  },
  button: {
    width: "auto",
    marginTop: "50px",
    padding: "1% 3% 1% 3%",
    color: White,
    background: PrimaryColor,
    borderRadius: "5px",
    textTransform: "none",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "@media (max-width: 640px)": {
      marginTop: "10%",
    },
  },
}));
