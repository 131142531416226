import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Button as Btn, Typography } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import ProductIcon from "../../assets/admin/bundle/product-icon.svg";
import ServiceIcon from "../../assets/admin/bundle/service-icon.svg";
import BundleIcon from "../../assets/admin/bundle/bundle-icon.svg";
import ProductModal from "./product/ProductModal";
import BundleModal from "./bundle/BundleModal";
import useStyles from "./styles";
import UltraBundleModal from "./bundle/UltraBundleModal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

const ItemModal = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Btn
        className={classes.headerButton}
        variant="contained"
        onClick={handleOpen}
      >
        + Add Item
      </Btn>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            <Typography className={classes.title}>Choose option</Typography>
            <Button onClick={handleClose} sx={{ color: "black" }}>
              <CloseIcon />
            </Button>
          </div>
          <div className={classes.contentContainer}>
            <div className={classes.roundedDiv}>
              <img
                src={ProductIcon}
                className={classes.mainIcon}
                alt="product icon"
              />
            </div>
            <div className={classes.contentDiv}>
              <ProductModal />
              <Typography>
                Lorem ipsum dolor sit amet consectetur. Consequat varius diam
                consectetur aliquam ultrices in eget convallis. Facilisis id
                ipsum eget ut. Ut urna egestas id neque habitasse.
              </Typography>
            </div>
          </div>
          <div className={classes.contentContainer}>
            <div className={classes.roundedDiv}>
              <img
                src={ServiceIcon}
                className={classes.mainIcon}
                alt="service icon"
              />
            </div>
            <div className={classes.contentDiv}>
              <ProductModal isProduct={false} />
              <Typography>
                Lorem ipsum dolor sit amet consectetur. Consequat varius diam
                consectetur aliquam ultrices in eget convallis. Facilisis id
                ipsum eget ut. Ut urna egestas id neque habitasse.
              </Typography>
            </div>
          </div>
          <div className={classes.contentContainer}>
            <div className={classes.roundedDiv}>
              <img
                src={BundleIcon}
                className={classes.mainIcon}
                alt="bundle icon"
              />
            </div>
            <div className={classes.contentDiv}>
              <BundleModal />
              <Typography>
                Lorem ipsum dolor sit amet consectetur. Consequat varius diam
                consectetur aliquam ultrices in eget convallis. Facilisis id
                ipsum eget ut. Ut urna egestas id neque habitasse.
              </Typography>
            </div>
          </div>
          {/* <div className={classes.contentContainer}>
            <div className={classes.roundedUltraDiv}>
              <img
                src={BundleIcon}
                className={classes.smallIcon}
                alt="bundle icon"
                style={{ marginTop: "10px" }}
              />
              <img
                src={BundleIcon}
                className={classes.smallIcon}
                alt="bundle icon"
                style={{ marginBottom: "10px" }}
              />
            </div>
            <div className={classes.contentDiv}>
              <UltraBundleModal />
              <Typography>
                Lorem ipsum dolor sit amet consectetur. Consequat varius diam
                consectetur aliquam ultrices in eget convallis. Facilisis id
                ipsum eget ut. Ut urna egestas id neque habitasse.
              </Typography>
            </div>
          </div> */}
        </Box>
      </Modal>
    </div>
  );
};

export default ItemModal;
