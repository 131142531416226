import { makeStyles } from "@material-ui/core/styles";
import {
  PrimaryColor,
  White,
  LightPrimaryColor,
  Black,
  InputColor,
  LightGrey,
  DarkGrey,
} from "../../shared/styles/colors";

export default makeStyles(() => ({
  headerDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 640px)": {
      alignItems: "center",
    },
  },
  headerSubDiv: {
    display: "flex",
    flexDirection: "row",
  },
  headerButton: {
    width: "100%",
    textTransform: "none",
    border: "none",
    color: White,
    background: PrimaryColor,
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  title: {
    color: Black,
    fontSize: "1.3rem",
    fontWeight: "700",
  },
  form: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: "15px",
    alignItems: "start",
    justifyContent: "center",
  },
  label: {
    fontWeight: "600",
  },
  inputGroup: {
    display: "flex",
    flexDirection: "row",
    width: "50% !important",
    alignItems: "center",
    padding: 0,
    marginTop: "20px",
    "@media (max-width: 640px)": {
      //flexDirection: "column",
      width: "100% !important",
    },
  },
  input: {
    maxWidth: "95% !important",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    border: ".5px solid #7E7D7D",
    background: White,
    color: Black,
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
    "& div, input, button": {
      //child
      border: "none",
    },
  },
  button: {
    padding: "0.5rem",
    margin: "0.5rem",
    maxWidth: "15rem",
    color: White,
    background: PrimaryColor,
    borderRadius: "5px",
    textTransform: "none",
    "&:hover": {
      background: LightPrimaryColor,
    },
    "&:disabled": {
      color: "grey",
      cursor: "none",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  externalButton: {
    padding: "0.5rem",
    margin: "0.5rem",
    maxWidth: "15rem !important",
    color: PrimaryColor,
    background: White,
    borderRadius: "5px",
    border: "1px solid #1e2e55",
    textTransform: "none",
    "&:hover": {
      background: LightPrimaryColor,
      color: White,
      border: "1px",
    },
    "@media (max-width: 640px)": {
      width: "auto",
      marginTop: "10%",
    },
  },
  calendar: {
    borderRadius: "10px",
    padding: "3px",
    border: ".1px solid grey",
    width: "100%",
    "& div, button": {
      borderRadius: "20px",
      color: PrimaryColor,
      "&:disabled": {
        color: DarkGrey,
      },
    },
  },
  //Details
  inputDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100% !important",
  },
  inputDetail: {
    width: "95% !important",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    background: LightGrey,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
    },
    "& div, input, button": {
      //child
      border: "none",
    },
  },
  textareaDetail: {
    width: "95% !important",
    height: "auto",
    maxHeight: "10rem",
    border: "none",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem",
    background: LightGrey,
    color: InputColor,
    "@media (max-width: 640px)": {
      width: "100% !important",
      maxHeight: "6rem",
    },
  },
}));
