export default class Pathnames {
  //FrontOffice
  static readonly HOMEPAGE = "/";
  static readonly REQUEST = "/quote";
  static readonly GALLERY = "/gallery";
  static readonly REVIEWS = "/reviews";
  static readonly ABOUT = "/about";
  static readonly CONTACT = "/contact";
  static readonly SERVICE = "/service";
  static readonly TERMS = "/terms-and-conditions";
  static readonly PRIVACY = "/privacy-policy";
  static readonly WILDCARD = "/*";
  static readonly SCHEDULE = "/appointment";
  static readonly FINANCE = "/financing";
  static readonly THANKYOU = "/thank-you"
  //Category Services
  static readonly KITCHEN_SERVICE = "/service/Kitchen";
  static readonly BATHROOM_SERVICE = "/service/Bathroom";
  static readonly PAINTING_AND_STAINING_SERVICE =
    "/service/painting_and_staining";
  static readonly DOOR_AND_WINDOWS_SERVICE = "/service/door_and_windows";
  static readonly FLOORING_SERVICE = "/service/Flooring";
  static readonly WASHING_PRESSURE_SERVICE = "/service/washing_pressure";
  static readonly WALLS_SERVICE = "/service/Walls";
  static readonly CEILINGS_SERVICE = "/service/Ceilings";
  static readonly PLUMBING_SERVICE = "/service/Plumbing";
  static readonly BASEMENT_SERVICE = "/service/Basement";
  static readonly PORCH_AND_DECK_SERVICE = "/service/porch_and_decks";
  static readonly ADDITIONS_SERVICE = "/service/Additions";
  static readonly FRAMING_AND_SHEETROCK_SERVICE =
    "/service/framing_and_sheetrock";
  static readonly CARPENTRY_SERVICE = "/service/Carpentry";
  //Admin
  static readonly ADMIN = "/admin";
  static readonly LOGIN = "/admin/login";
  //Dashboard
  static readonly DASHBOARD = "/admin/dashboard";
  //Media Management
  static readonly GALLERY_LIST = "/admin/media-management/gallery";
  static readonly REVIEWS_LIST = "/admin/media-management/reviews";
  //Content Management
  static readonly APPOINTMENT_LIST = "/admin/content-management/appointments";
  static readonly CLIENTS_LIST = "/admin/content-management/clients";
  static readonly REQUESTS_LIST = "/admin/content-management/requests";
  static readonly CONTACTS_LIST = "/admin/content-management/contacts";
  static readonly FAQ_LIST = "/admin/content-management/faqs";
  static readonly ESTIMATES_LIST = "/admin/content-management/estimates";
  static readonly PRODUCTS_SERVICES_LIST =
    "/admin/content-management/products-services";
  static readonly PRODUCTS_SERVICES_BUNDLE_LIST =
    "/admin/content-management/products-services/bundles";
  static readonly PRODUCTS_SERVICES_CATEGORY_LIST =
    "/admin/content-management/products-services/categories";
  static readonly PRODUCTS_SERVICES_VENDOR_LIST =
    "/admin/content-management/products-services/vendors";
  static readonly TEAM_LIST = "/admin/content-management/teams";
  static readonly PROJECTS_LIST = "/admin/content-management/projects";
  //General Management
  static readonly SERVICES_LIST = "/admin/general-management/services";
  static readonly STATUS_LIST = "/admin/general-management/status";
  //InternalServices
  static readonly USER_LIST = "/admin/internal/users";
  static readonly EMAIL_LIST = "/admin/internal/custom_emails";
  static readonly MESSAGE_LIST = "/admin/internal/messages";
  static readonly LOGS_LIST = "/admin/internal/logs";
  static readonly ERROR_LIST = "/admin/internal/errors";
  static readonly ROLE_LIST = "/admin/internal/roles";
  //Other
  static readonly SETTINGS = "/admin/settings";
  static readonly PROFILE = "/admin/profile";
  //Account
  static readonly ACTIVATE_ACCOUNT = "/admin/account/active";
}
