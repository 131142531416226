import React, { useState } from "react";
import { Box, Button, Modal } from "@mui/material";
import { Typography } from "@material-ui/core";
import Status from "../../model/enum/StatusClass";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ContactModal from "./ContactModal";
import MessageModel from "../../model/message";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "80%",
  },
};

interface Props {
  contact: MessageModel;
  handleUpdate: any;
  handleDelete: any;
}

const ContactDetailModal = ({ contact, handleUpdate, handleDelete }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>
        <MoreVertIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        <Box sx={style}>
          <div className={classes.headerDiv}>
            <Typography className={classes.title}>
              <div style={Status.mapStatusDivColor(contact.Status?.name)}>
                <div
                  style={Status.mapStatusRoundedColor(
                    contact.Status?.name,
                    false
                  )}
                ></div>
              </div>
            </Typography>
            <div className={classes.headerSubDiv}>
              <ContactModal
                contact={contact}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
              />
              <Button onClick={handleClose} sx={{ color: "black" }}>
                <CloseIcon />
              </Button>
            </div>
          </div>
          <form className={classes.form}>
            <Typography className={classes.label}>Client</Typography>
            <input
              className={classes.inputDetail}
              type="text"
              value={contact.Client?.firstName + " " + contact.Client?.lastName}
              disabled
            />
            <Typography className={classes.label}>Message</Typography>
            <textarea
              className={classes.textareaDetail}
              value={contact.text}
              disabled
            />
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default ContactDetailModal;
