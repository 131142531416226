import React, { useEffect, useState } from "react";
import { Box, Button, Modal, Tabs, Tab } from "@mui/material";
import {
  Button as Btn,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { Spinner } from "../../components";
import {
  Black,
  DarkGrey,
  LightGrey,
  LightPrimaryColor,
  PrimaryColor,
} from "../../shared/styles/colors";
import { useCookies } from "react-cookie";

import DeleteModal from "../modal/DeleteModal";

import StatusModel from "../../model/status";
import ClientModel from "../../model/client";
import EstimateModel from "../../model/estimate";
import BundleModel from "../../model/bundle";
import ProductModel from "../../model/product";
import BundleItemModel from "../../model/bundleItem";

import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import MainLogo from "../../assets/main.png";
import TransformDate from "../../utils/TransformDate";
import ApiService from "../../service/ApiService";
import DatabaseEntities from "../../model/enum/DatabaseEntities";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "../../assets/admin/edit-icon.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClientModal from "../client/ClientModal";
import useStyles from "./styles";
import "../styles/tableStyles.css";
import PaymentMethodModel from "../../model/paymentMethod";
import Calendar from "react-calendar";
import styled from "styled-components";
import StagedPayment from "../../model/stagedPayment";
import AlertService from "../../service/AlertService";
import ToastMessages from "../../model/enum/ToastMessages";

export const CalendarWrapper = styled.div`
  .react-calendar {
    width: 110%;
    background: white;
    border-radius: 5px;
  }
  .react-calendar__navigation__label,
  .react-calendar__navigation__arrow {
    background-color: white; /* Custom color */
    color: black; /* Button text color */
    border: none; /* Remove default border */
    padding: 5px 10px;
    border-radius: 20px;
    cursor: pointer;
  }

  .react-calendar__navigation__arrow:hover {
    background-color: #0056b3; /* Darker shade on hover */
  }

  .react-calendar__tile--active,
  .react-calendar__tile--active:after {
    color: white; /* Text color for active date */
    background: #1e2e55;
    border-radius: 20px; /* Optional: make the button rounded */
  }

  .react-calendar__tile--active:hover {
    background-color: #1e2e5580; /* Darker color on hover */
  }
`;

const style = {
  position: "absolute" as "absolute",
  top: "5%", // Start from a fixed top position
  left: "50%",
  transform: "translateX(-50%)", // Only horizontally center the modal
  width: "90% !important",
  maxWidth: "85rem",
  bgcolor: "#F9F9F9",
  border: "none",
  borderRadius: "8px",
  boxShadow: 24,
  pt: 2,
  px: 2,
  pb: 3,
  maxHeight: "90vh", // Restrict maximum height to 80% of the viewport height
  overflowY: "auto", // Enable vertical scrolling
  "@media (max-width: 640px)": {
    width: "85%",
  },
};

type Item = {
  id: number;
  task: string;
  description: string;
  quantity: number;
  rate: number;
  amount: number;
};

type Task = {
  id: number;
  task: string;
  description: string;
  quantity: number;
  rate: number;
  amount: number;
  BundleItems: Array<BundleItemModel>;
};

const EstimateModal = ({
  estimate,
  handleCreate,
  handleUpdate,
  handleDelete,
}: any) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [cookies] = useCookies(["session"]);
  const [toggleSpinner, setToggleSpinner] = useState(false);

  const [value, setValue] = useState(0); // Active tab

  const edit = estimate != null;
  const id = edit ? estimate.id : 0;
  const [code, setCode] = useState(edit ? estimate.code : generateKey());
  const [client, setClient] = useState(edit ? estimate.Client : null);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [dueDate, setDueDate] = useState(edit ? estimate.dueDate : null);
  const [status, setStatus] = useState(edit ? estimate.Status : null);
  const [statusList, setStatusList] = useState<StatusModel[]>([]);
  const userId = edit ? estimate.User.id : cookies.session.userId;
  const [notes, setNotes] = useState(edit ? estimate.notes : null);
  const [approved, setApproved] = useState(edit ? estimate.approved : false);
  const [acceptedBy, setAcceptedBy] = useState(
    edit ? estimate.acceptedBy : null
  );
  const [acceptedAt, setAcceptedAt] = useState(
    edit ? estimate.acceptedAt : null
  );
  const [startsAt, setStartsAt] = useState(edit ? estimate.startsAt : null);
  const [urgentPayment, setUrgentPayment] = useState(
    edit ? estimate.urgentPayment : false
  );
  const [downPayment, setDownPayment] = useState(edit ? true : false);
  const createdAt = edit ? new Date(estimate.createdAt) : new Date();
  const [total, setTotal] = useState(edit ? estimate.total : null);
  const [discount, setDiscount] = useState(0);
  const [bundles, setBundles] = useState<BundleModel[]>([]);
  const [products, setProducts] = useState<ProductModel[]>([]);
  const [items, setItems] = useState<Task[]>(edit ? estimate.items : []);
  const [paymentMethodId, setPaymentMethodId] = useState(
    edit ? estimate.paymentMethod : null
  );
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodModel[]>(
    []
  );
  const [stagedAmount, setStagedAmount] = useState(0);
  const [size, setSize] = useState("");
  const [endSize, setEndSize] = useState("");

  //Get clients list from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<ClientModel[]>(DatabaseEntities.CLIENTS).then(
        (data: ClientModel[]) => {
          data.sort((a, b) => a.firstName.localeCompare(b.firstName));
          setClients(data);
        }
      );

      setToggleSpinner(false);
    };

    if (open && clients.length <= 0) fetchData();
  }, [open, clients]);

  //Get status list from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<StatusModel[]>(
        `${DatabaseEntities.STATUS}?type=Estimates`
      ).then((data: StatusModel[]) => {
        setStatusList(data);
      });

      setToggleSpinner(false);
    };

    if (open && statusList.length <= 0) fetchData();
  }, [open, statusList]);

  // Get all the bundles items from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<BundleModel[]>(DatabaseEntities.BUNDLES).then(
        (data) => {
          if (data.length == 0) setToggleSpinner(false);
          setBundles(data);
        }
      );
      setToggleSpinner(false);
    };

    if (open && bundles.length <= 0) fetchData();
  }, [open, bundles]);

  // Get all the products items from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<ProductModel[]>(DatabaseEntities.PRODUCTS).then(
        (data) => {
          if (data.length == 0) setToggleSpinner(false);
          setProducts(data);
        }
      );
      setToggleSpinner(false);
    };

    if (open && products.length <= 0) {
      fetchData();
    }
  }, [open, products]);

  // Get all the payment methods from the database
  useEffect(() => {
    const fetchData = async () => {
      setToggleSpinner(true);

      await ApiService.get<PaymentMethodModel[]>(
        "frontend/paymentMethods"
      ).then((data) => {
        if (data.length == 0) setToggleSpinner(false);
        setPaymentMethods(data);
      });
      setToggleSpinner(false);
    };

    if (open && paymentMethods.length <= 0) fetchData();
  }, [open, paymentMethods]);

  const handleTabChange = (e: any, newValue: number) => {
    setValue(newValue);
  };

  const handleClient = (e: any) => {
    let value = e.target.value;
    var clt = clients.find((x) => x.id == value);
    setClient(clt);
  };

  const handleStatus = (e: any) => {
    let value = e.target.value;
    var stt = statusList.find((x) => x.id == value);
    setStatus(stt);
  };

  const handleAddTask = () => {
    const newTask: Task = {
      id: items.length + 1,
      task: "",
      description: "",
      quantity: 0,
      rate: 0,
      amount: 0,
      BundleItems: [],
    };
    setItems([...items, newTask]);
  };

  const handleAddItem = (e: any, index: number) => {
    const updatedItems = [...items];

    const product: ProductModel = {
      name: "",
      price: 0,
      isProduct: false,
      categoryId: 0,
    };

    const item: BundleItemModel = {
      bundleId: id,
      productId: 0,
      Product: product,
      quantity: 0,
    };
    updatedItems[index].BundleItems.push(item);
    setItems(updatedItems);
  };

  const handleTask = (e: any, index: number) => {
    //const task: ProductModel = products[index];
    let pos = parseInt(e.target.value);
    var task = bundles.find((x) => x.id === pos);

    if (!task) return;

    //update task info based on the bundle info
    const updatedItems = [...items];

    updatedItems[index].task = task.name;
    updatedItems[index].description = task.description ?? "";
    updatedItems[index].BundleItems = task.BundleItems ?? [];

    //vars to calculate the totals
    let totalRate: number = 0;
    let totalQuantity: number = 0;
    let totalAmount: number = 0;

    //map the child items to calculate the total amount, quantity and rate of the bundle
    task.BundleItems?.map((item: BundleItemModel) => {
      totalRate += item.Product?.price ? parseInt(item.Product.price) : 0;
      totalQuantity += item.quantity;
      totalAmount += item.Product?.price
        ? item.Product.price * item.quantity
        : 0;
    });
    updatedItems[index].rate = totalRate;
    updatedItems[index].quantity = totalQuantity;
    updatedItems[index].amount = totalAmount;

    setItems(updatedItems);
  };

  const handleItem = (e: any, index: number, pos: number) => {
    const updatedItems = [...items];
    let value = e.target.value;
    let prod = products.find((x) => x.name === value);
    if (prod) {
      updatedItems[index].BundleItems[pos].Product = prod;
      setItems(updatedItems);
    }
  };

  const handleDescription = (e: any, index: number, pos?: number) => {
    let description = e.target.value;

    const updatedItems = [...items];
    if (pos != undefined)
      updatedItems[index].BundleItems[pos].Product.description = description;
    else updatedItems[index].description = description;

    setItems(updatedItems);
  };

  const handleQuantity = (e: any, index: number, pos?: number) => {
    const updatedItems = [...items];
    let value = parseInt(e.target.value);
    if (pos != undefined) {
      updatedItems[index].BundleItems[pos].quantity = value;
      //updatedItems[index].amount = value * updatedItems[index].rate;
    }
    setItems(updatedItems);
    calculateBundleTotal(index, updatedItems);
  };

  const handleRate = (e: any, index: number, pos?: number) => {
    const updatedItems = [...items];
    let value = parseInt(e.target.value);
    if (pos != undefined) {
      updatedItems[index].BundleItems[pos].Product.price = value;
      //updatedItems[index].BundleItems[pos]. = value * updatedItems[index].quantity;
    }
    setItems(updatedItems);
    calculateBundleTotal(index, updatedItems);
  };

  const handleAmount = (e: any, index: number, pos?: number) => {
    const updatedItems = [...items];
    if (pos != undefined) {
      updatedItems[index].BundleItems[pos].Product.price =
        parseInt(e.target.value) || 0;
    }
    setItems(updatedItems);
    calculateBundleTotal(index, updatedItems);
  };

  const handleDeleteTask = (index: number) => {
    setItems(items.filter((_, i) => i !== index));
  };

  const handleDeleteItem = (index: number, pos: number) => {
    const task = items[index];
    const updatedItems = [...items];
    const newItems = task.BundleItems?.filter((_, i) => i !== pos);

    updatedItems[index].BundleItems = newItems ?? [];

    setItems(updatedItems);

    calculateBundleTotal(index, updatedItems);

    if (newItems && newItems.length == 0) handleDeleteTask(index);
  };

  const handleDiscount = (e: any) => {
    let value = e.target.value;
    //TODO: add discount in value instead of percentage

    if (value >= 0 && value <= 100) setDiscount(value);
  };

  const handleNotes = (e: any) => {
    setNotes(e.target.value);
  };

  // Handle selection for each category
  const handleStartDate = (selectedDay: string) => {
    setSize(selectedDay);
    // based on selected size, set the start date
    switch (selectedDay) {
      case "Tomorrow":
        setStartsAt(new Date().setDate(new Date().getDate() + 1));
        break;
      case "In three days":
        setStartsAt(new Date().setDate(new Date().getDate() + 3));
        break;
      case "Next week":
        setStartsAt(new Date().setDate(new Date().getDate() + 7));
        break;
    }
  };

  // Handle selection for each category
  const handleDueDate = (selectedDay: string) => {
    setEndSize(selectedDay);
    // based on selected size, set the start date
    switch (selectedDay) {
      case "Next week":
        setDueDate(new Date().setDate(new Date().getDate() + 7));
        break;
      case "Next month":
        setDueDate(new Date().setDate(new Date().getDate() + 30));
        break;
    }
  };

  const handlePaymentMethod = (e: any) => {
    setPaymentMethodId(e.target.value);
  };

  const handleDownPayment = (e: any) => {
    setDownPayment(!downPayment);
  };

  const handleUrgentPayment = (e: any) => {
    setUrgentPayment(!urgentPayment);
  };

  const handleStagedAmount = (e: any) => {
    let value = parseInt(e.target.value);
    if (value >= 0 && value <= total) setStagedAmount(value);
  };

  const calculateBundleTotal = (index: number, updatedItems: Task[]) => {
    //vars to calculate the totals
    let totalRate: number = 0;
    let totalQuantity: number = 0;
    let totalAmount: number = 0;

    //map the child items to calculate the total amount, quantity and rate of the bundle
    updatedItems[index].BundleItems.map((item: BundleItemModel) => {
      totalRate += item.Product?.price ? parseInt(item.Product.price) : 0;
      totalQuantity += item.quantity;
      totalAmount += item.Product?.price
        ? item.Product.price * item.quantity
        : 0;
    });
    updatedItems[index].rate = totalRate;
    updatedItems[index].quantity = totalQuantity;
    updatedItems[index].amount = totalAmount;

    setItems(updatedItems);
  };

  // Calculate the total amount of the estimate everytime the amount, rate or quantity changes
  useEffect(() => {
    let val = 0;
    items.map((x) => {
      val += x.amount;
    });
    // Calculate the discount amount
    let discountAmount = (discount / 100) * val;
    // Calculate the final amount after discount, with 2 decimal case
    let finalAmount = parseFloat((val - discountAmount).toFixed(2));
    setTotal(finalAmount);
  }, [
    handleAmount,
    handleRate,
    handleQuantity,
    handleDiscount,
    handleDeleteItem,
  ]);

  // Check if the input is valid
  const invalidInput = (): boolean => {
    //Check if bundle has task and positive quantity

    let invalid: boolean =
      items.length > 0 &&
      (items.some((x) => x.task == "") || items.some((x) => x.quantity == 0));

    invalid =
      !code ||
      !client ||
      !status ||
      !userId ||
      total === 0 ||
      !startsAt ||
      !dueDate;

    return invalid;
  };

  // Submit the form
  const submit = async () => {
    if (invalidInput()) return;

    setToggleSpinner(true);

    //create a method to submit the form
    const payload: EstimateModel = {
      id: id,
      code: `${code}`,
      clientId: client?.id,
      total: total,
      dueDate: dueDate,
      statusId: status?.id,
      userId: userId,
      notes: notes,
      approved: approved,
      acceptedBy: acceptedBy,
      acceptedAt: acceptedAt,
      startsAt: startsAt,
      urgentPayment: urgentPayment,
      items: items,
    };

    if (edit) {
      await handleUpdate(payload);
    } else {
      const createdEstimate = await handleCreate(payload);
      if (downPayment && createdEstimate?.id) {
        const stagedPayment: StagedPayment = {
          id: 0,
          estimateId: createdEstimate.id,
          paymentMethodId: paymentMethodId,
          amount: stagedAmount,
        };

        try {
          await ApiService.post<StagedPayment>(
            DatabaseEntities.STAGED_PAYMENTS,
            stagedPayment
          );
        } catch {
          console.log(ToastMessages.CREATED_FAILED);
        }
      }
    }

    setToggleSpinner(false);
    //reset all constants
    setCode(generateKey());
    setClient(null);
    setDueDate(null);
    setStatus(null);
    setNotes(null);
    setApproved(false);
    setAcceptedBy(null);
    setAcceptedAt(null);
    setStartsAt(null);
    setUrgentPayment(false);
    setItems([]);
    setTotal(null);
    setDiscount(0);
    setStagedAmount(0);
    setPaymentMethodId(null);
    setDownPayment(false);
    setSize("");
    setEndSize("");
    setOpen(false);

    handleClose();
  };

  return (
    <div>
      {edit ? (
        <Button onClick={handleOpen}>
          <img src={EditIcon} alt="edit icon" />
        </Button>
      ) : (
        <Btn
          className={classes.headerButton}
          variant="contained"
          onClick={handleOpen}
        >
          + Add Estimate
        </Btn>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflowY: "auto" }}
      >
        {toggleSpinner ? (
          <Spinner />
        ) : (
          <Box sx={style}>
            <Card style={{ padding: "25px" }}>
              <div className={classes.headerDiv}>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: DarkGrey,
                    textAlign: "center",
                    width: "auto",
                    fontSize: "1.1rem",
                  }}
                >
                  Estimate
                </Typography>
                <div className={classes.headerSubDiv}>
                  {edit && (
                    <Typography className={classes.title}>
                      <DeleteModal
                        item={estimate}
                        handleDelete={handleDelete}
                      />
                    </Typography>
                  )}
                  <Button onClick={handleClose} sx={{ color: "black" }}>
                    <CloseIcon />
                  </Button>
                </div>
              </div>
              <hr />
              <div>
                <Box
                  sx={{ width: "20%", borderBottom: 1, borderColor: "divider" }}
                >
                  <Tabs
                    value={value}
                    onChange={handleTabChange}
                    centered
                    variant="standard"
                    textColor="inherit"
                    indicatorColor="primary"
                    TabIndicatorProps={{
                      sx: { height: 2, bgcolor: PrimaryColor }, // Customize the underline
                    }}
                  >
                    <Tab
                      label={edit ? "Edit" : "Create"}
                      sx={{
                        opacity: value === 0 ? 1 : 0.5,
                        textTransform: "capitalize",
                      }}
                    />
                    {edit && (
                      <Tab
                        label="PDF View"
                        sx={{
                          opacity: value === 1 ? 1 : 0.5,
                          textTransform: "capitalize",
                        }}
                      />
                    )}
                  </Tabs>
                </Box>
              </div>
            </Card>
            {/** Form */}
            <form className={classes.form}>
              <Card style={{ width: "80%", padding: "5px" }}>
                <CardContent>
                  <div className={classes.headerDiv}>
                    <div className="d-flex col align-items-center">
                      <Typography
                        style={{
                          color: PrimaryColor,
                          fontSize: "2rem",
                          fontWeight: "700",
                          textTransform: "uppercase",
                          marginRight: "10px",
                        }}
                      >
                        Estimate
                      </Typography>
                      <Typography
                        style={{ color: PrimaryColor }}
                      >{`#${code}`}</Typography>
                    </div>
                    <img src={MainLogo} alt="logo" width={120} height={50} />
                  </div>
                  <div className="d-flex col w-100 mt-2">
                    <div className="d-flex row">
                      <Typography>Setup Remodeling Inc.</Typography>
                      <Typography>72 Cash St.</Typography>
                      <Typography>Fall River Ma 02723</Typography>
                    </div>
                    <div className="d-flex row mx-1">
                      <Typography>sales@setupremodeling.com</Typography>
                      <Typography>+1 (800)-530-4099</Typography>
                      <Typography>wwww.setupremodeling.com</Typography>
                    </div>
                  </div>
                  <div className={`${classes.inputGroup}`}>
                    <select
                      className={classes.input}
                      onChange={handleClient}
                      value={client?.id}
                    >
                      <option value="">Select Client</option>
                      {clients.map((clt: ClientModel) => {
                        return (
                          <option key={clt.id} value={clt.id}>
                            {clt.firstName} {clt.lastName}
                          </option>
                        );
                      })}
                    </select>
                    <ClientModal
                      externalService={true}
                      resetList={() => setClients([])}
                      setClient={(clt: ClientModel) => setClient(clt)}
                    />
                  </div>
                  <hr />
                  <div className="table-container">
                    <table className="styled-table">
                      <thead style={{ color: "rgba(117, 117, 117, 1)" }}>
                        <td>Task</td>
                        <td>Description</td>
                        <td>Quantity</td>
                        <td>Rate</td>
                        <td>Amount</td>
                        <td>Action</td>
                      </thead>
                      <tbody>
                        {items.map((row, index) => (
                          <React.Fragment key={row.id}>
                            {/* Parent Row */}
                            <tr key={row.id}>
                              <td>
                                <select
                                  className={classes.input}
                                  value={row.task}
                                  onChange={(e) => handleTask(e, index)}
                                  disabled={items[index].task != ""}
                                >
                                  <option value="" disabled>
                                    Select task
                                  </option>
                                  {bundles.map((bnd: BundleModel) => {
                                    return (
                                      <option key={bnd.id} value={bnd.id}>
                                        {bnd.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  placeholder="Add a description.."
                                  value={items[index].description}
                                  onChange={(e) => handleDescription(e, index)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  placeholder="01"
                                  min={1}
                                  value={items[index].quantity}
                                  disabled={row.BundleItems.length > 0}
                                  onChange={(e) => handleQuantity(e, index)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  placeholder="01"
                                  min={1}
                                  value={items[index].rate}
                                  disabled={row.BundleItems.length > 0}
                                  onChange={(e) => handleRate(e, index)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  placeholder="01"
                                  min={1}
                                  value={items[index].amount}
                                  disabled={row.BundleItems.length > 0}
                                  onChange={(e) => handleAmount(e, index)}
                                />
                              </td>
                              <td>
                                <Button
                                  className="delete-btn"
                                  onClick={() => handleDeleteTask(index)}
                                >
                                  🗑️
                                </Button>
                              </td>
                            </tr>
                            {/* Nested Rows */}
                            <>
                              {row.BundleItems.map((child, pos) => (
                                <tr key={child.id}>
                                  <td
                                    style={{
                                      paddingLeft: "30px",
                                    }}
                                  >
                                    <select
                                      className={classes.input}
                                      style={{
                                        background: "#F1F2F3",
                                        color: "grey",
                                      }}
                                      value={child.Product?.name}
                                      onChange={(e) =>
                                        handleItem(e, index, pos)
                                      }
                                      disabled={
                                        items[index].BundleItems[pos].Product
                                          .name != ""
                                      }
                                    >
                                      <option value="" disabled>
                                        Select task
                                      </option>
                                      {products.map((prod: ProductModel) => {
                                        return (
                                          <option
                                            key={prod.id}
                                            value={prod.name}
                                          >
                                            {prod.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </td>
                                  <td>
                                    <input
                                      style={{
                                        background: "#F1F2F3",
                                        color: "grey",
                                      }}
                                      type="text"
                                      placeholder="Add a description.."
                                      value={child.Product.description}
                                      onChange={(e) =>
                                        handleDescription(e, index, pos)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      style={{
                                        background: "#F1F2F3",
                                        color: "grey",
                                      }}
                                      type="number"
                                      placeholder="01"
                                      min={1}
                                      value={child.quantity}
                                      onChange={(e) =>
                                        handleQuantity(e, index, pos)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      style={{
                                        background: "#F1F2F3",
                                        color: "grey",
                                      }}
                                      type="number"
                                      placeholder="01"
                                      min={1}
                                      value={child.Product.price}
                                      onChange={(e) =>
                                        handleRate(e, index, pos)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <input
                                      style={{
                                        background: "#F1F2F3",
                                        color: "grey",
                                      }}
                                      type="number"
                                      placeholder="01"
                                      min={1}
                                      value={
                                        child.Product.price * child.quantity
                                      }
                                      onChange={(e) =>
                                        handleAmount(e, index, pos)
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Button
                                      className="delete-btn"
                                      onClick={() =>
                                        handleDeleteItem(index, pos)
                                      }
                                    >
                                      🗑️
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  <Btn
                                    className={classes.externalButton}
                                    style={{ width: "100%" }}
                                    onClick={(e) => handleAddItem(e, index)}
                                  >
                                    + Add Item
                                  </Btn>
                                </td>
                              </tr>
                            </>
                          </React.Fragment>
                        ))}
                        <tr className="add-item-row">
                          <td colSpan={6} className="add-item">
                            <span onClick={handleAddTask}>+ Add Task</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    className="d-flex col w-100 mt-2 align-items-center justify-content-end"
                    style={{ width: "200px" }}
                  >
                    <Typography>Discount:</Typography>
                    <input
                      className={classes.input}
                      type="number"
                      value={discount}
                      min={0}
                      max={100}
                      onChange={handleDiscount}
                    />
                    <span>%</span>
                  </div>
                  <div className="d-flex col w-100 mt-2 align-items-center justify-content-end">
                    <Typography>Total Amount:</Typography>
                    <Typography
                      style={{
                        color: PrimaryColor,
                        fontSize: "20px",
                        marginLeft: "10px",
                      }}
                    >
                      ${total},00
                    </Typography>
                  </div>
                  <div className="d-flex row mt-5 align-items-center">
                    <Typography>Notes to costumer</Typography>
                    <textarea
                      className={classes.input}
                      value={notes}
                      onChange={handleNotes}
                      placeholder="Thank you.."
                    />
                  </div>
                </CardContent>
              </Card>
              {/** Details */}
              <div
                style={{
                  width: "24%",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "15px",
                  padding: "5px",
                }}
              >
                <Card>
                  <CardContent>
                    <Typography
                      style={{
                        color: PrimaryColor,
                        fontSize: "20px",
                        fontWeight: 600,
                      }}
                    >
                      Details
                    </Typography>
                    <div className="d-flex col mt-4 justify-content-between align-items-center">
                      <Typography style={{ color: "grey", fontSize: "15px" }}>
                        Estimate total:
                      </Typography>
                      <Typography>${total},00</Typography>
                    </div>
                    {edit && (
                      <div className="d-flex col justify-content-between align-items-center">
                        <Typography style={{ color: "grey", fontSize: "15px" }}>
                          Due date:
                        </Typography>
                        <Typography>
                          {TransformDate.toCompleteDate(dueDate)}
                        </Typography>
                      </div>
                    )}
                    <div className="d-flex col justify-content-between align-items-center">
                      <Typography style={{ color: "grey", fontSize: "15px" }}>
                        Status:
                      </Typography>
                      <select
                        className={classes.input}
                        onChange={handleStatus}
                        value={status?.id}
                      >
                        <option value="">Select status</option>
                        {statusList.map((stts) => {
                          return (
                            <option key={stts.id} value={stts.id}>
                              {stts.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {edit && (
                      <div className="d-flex col justify-content-between align-items-center">
                        <Typography style={{ color: "grey", fontSize: "15px" }}>
                          Created At:
                        </Typography>
                        <Typography>
                          {TransformDate.toFullDateHours(createdAt)}
                        </Typography>
                      </div>
                    )}
                    <hr />
                    <div className="d-flex col justify-content-between align-items-center">
                      <Typography style={{ color: "grey", fontSize: "15px" }}>
                        Urgent payment
                      </Typography>
                      {urgentPayment ? (
                        <Button
                          sx={{ color: PrimaryColor }}
                          onClick={handleUrgentPayment}
                        >
                          <ToggleOnIcon />
                        </Button>
                      ) : (
                        <Button
                          sx={{ color: DarkGrey }}
                          onClick={handleUrgentPayment}
                        >
                          <ToggleOffIcon />
                        </Button>
                      )}
                    </div>
                    <div className="d-flex mt-2 row w-100 flex-wrap">
                      <Typography style={{ color: "grey", fontSize: "15px" }}>
                        Work starts:
                      </Typography>
                      {[
                        "Tomorrow",
                        "In three days",
                        "Next week",
                        "Select date",
                      ].map((sizeOption) => (
                        <label key={sizeOption} style={{ color: DarkGrey }}>
                          <input
                            type="radio"
                            name="size"
                            value={sizeOption}
                            checked={size === sizeOption}
                            onChange={() => handleStartDate(sizeOption)}
                            className="hidden"
                          />
                          {sizeOption}
                        </label>
                      ))}
                      {size === "Select date" && (
                        <CalendarWrapper>
                          <Calendar
                            className={classes.calendar}
                            calendarType={"US"}
                            value={startsAt}
                            tileDisabled={({ date }) => date.getDay() === 6} //disabled on saturdays
                            onChange={(date: any) => setStartsAt(date)}
                          />
                        </CalendarWrapper>
                      )}
                    </div>
                    <div className="d-flex mt-2 row w-100 flex-wrap">
                      <Typography style={{ color: "grey", fontSize: "15px" }}>
                        Work ends:
                      </Typography>
                      {["Next week", "Next month", "Select date"].map(
                        (option) => (
                          <label key={option} style={{ color: DarkGrey }}>
                            <input
                              type="radio"
                              name="endsize"
                              value={option}
                              checked={endSize === option}
                              onChange={() => handleDueDate(option)}
                              className="hidden"
                            />
                            {option}
                          </label>
                        )
                      )}
                      {endSize === "Select date" && (
                        <CalendarWrapper>
                          <Calendar
                            className={classes.calendar}
                            calendarType={"US"}
                            value={dueDate}
                            tileDisabled={({ date }) => date.getDay() === 6} //disabled on saturdays
                            onChange={(date: any) => setDueDate(date)}
                          />
                        </CalendarWrapper>
                      )}
                    </div>
                    <hr />
                    <div>
                      <div className="d-flex col justify-content-between align-items-center">
                        <Typography>Downpayment</Typography>
                        {downPayment ? (
                          <Button
                            sx={{ color: PrimaryColor }}
                            onClick={handleDownPayment}
                          >
                            <ToggleOnIcon />
                          </Button>
                        ) : (
                          <Button
                            sx={{ color: DarkGrey }}
                            onClick={handleDownPayment}
                          >
                            <ToggleOffIcon />
                          </Button>
                        )}
                      </div>
                      {downPayment && (
                        <>
                          <select
                            className={classes.input}
                            value={paymentMethodId}
                            onChange={handlePaymentMethod}
                          >
                            <option value="">Select payment method</option>
                            {paymentMethods.map((method) => {
                              return (
                                <option key={method.id} value={method.id}>
                                  {method.name}
                                </option>
                              );
                            })}
                          </select>
                          <input
                            type="number"
                            className={classes.input}
                            placeholder="Amount"
                            value={stagedAmount}
                            onChange={handleStagedAmount}
                          />
                          <Button sx={{ color: PrimaryColor }}>
                            + Add new
                          </Button>
                        </>
                      )}
                    </div>
                    {edit && (
                      <>
                        <hr />
                        <div>
                          <Btn
                            className={classes.button}
                            style={{ width: "100%" }}
                            disabled={invalidInput()}
                          >
                            Send estimate
                          </Btn>
                          <Btn
                            className={classes.externalButton}
                            style={{ width: "100%" }}
                            disabled={invalidInput()}
                          >
                            Convert to invoice
                          </Btn>
                        </div>
                      </>
                    )}
                  </CardContent>
                </Card>
                <div className="d-flex justify-content-end">
                  <Btn
                    className={classes.button}
                    disabled={invalidInput()}
                    onClick={submit}
                  >
                    Save
                  </Btn>
                </div>
              </div>
            </form>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default EstimateModal;

function generateKey(): number {
  // Generate a random number between 100000 and 999999
  const randomNumber = Math.floor(100000 + Math.random() * 900000);

  return randomNumber;
}
