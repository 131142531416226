import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import BarChart from "../../shared/components/charts/BarChart";
import LineChart from "../../shared/components/charts/LineChart";
import ApiService from "../../service/ApiService";
import cardStyles from "../styles/cardStyles";
import useStyles from "./styles";

const Stats = () => {
  const classes = useStyles();
  const styles = cardStyles();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState(new Date().getFullYear());
  const yearList = [2025, 2024, 2023];
  const [graphType, setGraphType] = useState("Bar");
  const graphTypes = ["Bar", "Line"];
  const [appointments, setAppointments] = useState<any[]>([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [requests, setRequest] = useState<any[]>([]);

  //Get all appointments from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<any[]>(`stats/appointments?year=${year}`).then(
        (data) => {
          setAppointments((appointments) => [...appointments, data]);
        }
      );
    };

    if (appointments.length <= 0) fetchData();
  }, [appointments]);

  //Get all requests from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<any[]>(`stats/requests?year=${year}`).then(
        (data) => {
          setRequest((requests) => [...requests, data]);
        }
      );
    };

    if (requests.length <= 0) fetchData();
  }, [requests]);

  //Get all contacts from the database
  useEffect(() => {
    const fetchData = async () => {
      await ApiService.get<any[]>(`stats/messages?year=${year}`).then(
        (data) => {
          setContacts((contacts) => [...contacts, data]);
        }
      );
    };

    if (contacts.length <= 0) fetchData();
  }, [contacts]);

  useEffect(() => {
    if (loading && dataFetched()) {
      setData({
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "June",
          "July",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Appointments",
            data: appointments.flat().map((data: any) => data.count), //returns a single array of all the counts
            //data: appointments.map((data: any) => data.count),
            backgroundColor: "rgba(24, 47, 88, 1)",
          },
          {
            label: "Messages",
            data: contacts.flat().map((data: any) => data.count),
            backgroundColor: "rgba(36, 71, 133, 1)",
          },
          {
            label: "Requests",
            data: requests.flat().map((data: any) => data.count),
            backgroundColor: "rgba(56, 110, 207, 1)",
          },
        ],
      });

      setLoading(false);
    }
  }, [appointments, requests, contacts]);

  const dataFetched = () => {
    return (
      appointments.length > 0 && requests.length > 0 && contacts.length > 0
    );
  };

  const handleYearChange = (e: any) => {
    setLoading(true);
    setYear((_year) => parseInt(e.target.value));
    setAppointments([]);
    setRequest([]);
    setContacts([]);
  };

  const handleTypeChange = (e: any) => {
    setGraphType(e.target.value);
  };

  const Loading = () => {
    return <>Loading...</>;
  };

  const Graph = () => {
    switch (graphType) {
      case "Bar":
        return <BarChart chartData={data} />;
      case "Line":
        return <LineChart chartData={data} />;
    }

    return <></>;
  };

  return (
    <>
      <Card raised className={styles.card}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <div className={styles.headerContainer}>
              <Typography className={styles.title}>Statistics</Typography>
              <div>
                <select
                  value={graphType}
                  className={classes.selector}
                  onChange={handleTypeChange}
                >
                  {graphTypes.map((graph: string) => (
                    <option>{graph}</option>
                  ))}
                </select>{" "}
                <select
                  className={classes.selector}
                  onChange={handleYearChange}
                  value={year}
                >
                  {yearList.map((y: number) => (
                    <option>{y}</option>
                  ))}
                </select>
              </div>
            </div>
            <CardContent className={classes.statsCardContent}>
              <Graph />
            </CardContent>
          </>
        )}
      </Card>
    </>
  );
};

export default Stats;
